<template>
  <chart
    :type="typeChart"
    :data="dataChart"
    :options="optionsChart"
    :width="widthChart"
    :height="heightChart"
    :plugins="pluginsChart"
  >
  </chart>
</template>

<script>
import Chart from 'vue-bulma-chartjs'

export default {
  name: 'BulmaChart',
  components: {
    Chart
  },
  props: {
    typeChart: { type: String, required: true },
    dataChart: { type: Object, required: true, default: () => ({}) },
    optionsChart: { type: Object, required: true, default: () => ({}) },
    widthChart: { type: Number },
    heightChart: { type: Number },
    pluginsChart: { type: [Object, Array], required: false, default: () => ({}) }
  }
}
</script>
